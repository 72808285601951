import React, { useEffect } from "react";
import { Link } from "gatsby"

import LayoutItouch from "../../components/Layout";
import Seo from "../../components/Seo";

const WarrantyRegistration = () => {
    useEffect(() => {

        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'powr-io';
        script.async = true;
        script.src = 'https://www.powr.io/powr.js?platform=html';
        document.getElementsByTagName('head')[0].appendChild(script);
    });

    return (
        <LayoutItouch>
            <Seo
                keywords={[`warranty`, `watch`]}
                title="X-Five Warranty Registration"
            />

            <section>
                <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
                    <div className="relative max-w-xl mx-auto">
                        <div className="pb-4">
                            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text-center">
                                Warranty Claim
                </h2>
                            <p className="mt-4 text-lg leading-6 text-gray-500 text-center">
                                Complete this form to process your X-Five Smartwatch Warranty Claim.
                </p>                                <p className="mt-4 text-lg leading-6 text-gray-500 text-center italic">
                                If you haven't already registered and would like to file a claim, please complete the registration form on <Link className="underline text-blue-400" to='/warrantyRegistration'>this page</Link> first.
                </p>
                        </div>

                        <div className="mt-12">
                            <div className="pb-20 pm-20">
                                <div class="powr-form-builder" id="fde9611a_1617634399"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </LayoutItouch>
    );
}

export default WarrantyRegistration;
